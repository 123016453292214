import React, { useContext, useState } from 'react';
import {
    assignDeptPermission,
    deleteInvitation,
    Invitation,
    removeDeptPermission,
} from '../../services/apiService';
import DeleteInvite from './DeleteInvite';
import ExtendedInfoComponent from './ExtendedUserInfo';
import SendReminders from './SendReminders';
import styles from './UserRow.module.css';
import AuthContext from '../../store/auth-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import Select from '../general-components/Select';
export interface UserListInfo {
    id: string;
    email: string;
    isAdmin?: string;
    name?: string;
    role?: string;
    expiryDate?: string;
    deptPermissions?: any[];
}

const UserRow: React.FC<{
    userInfo: UserListInfo;
    isInvite: boolean;
    invite?: Invitation;
    onChange?: (obj: any, remove?: boolean) => void;
    deptLicenses: number;
}> = ({ userInfo, isInvite, invite, onChange, deptLicenses }) => {
    const context = useContext(AuthContext);
    const userIsActive = (user: any) => {
        const expDate = new Date(user.expiryDate);
        const now = new Date();

        return expDate > now;
    };

    const [user, setUser] = useState(userInfo);

    const handleChange = (u: any) => {
        setUser(u);
        onChange(u);
    };

    const [permissions, setPermissions] = React.useState<any[]>(
        user.deptPermissions ?? []
    );

    const updatePermissions = async (p: any) => {
        const permExists = permissions.includes(p);
        let success = false;
        if (permExists) {
            success = await removeDeptPermission(user.id, p);
        } else {
            success = await assignDeptPermission(user.id, p);
        }
        if (success) {
            setPermissions(
                permExists
                    ? permissions.filter((x) => x !== p)
                    : [...permissions, p]
            );
        }
    };

    const isActiveClass = (user: any) => {
        if (userIsActive(user)) {
            return styles['active-user'];
        } else {
            return styles['inactive-user'];
        }
    };

    const extendInfo = (event: React.MouseEvent<HTMLElement>) => {
        // let parent = event.target as HTMLElement;
        // while (parent.tagName !== 'TR') {
        //     parent = parent.parentElement as HTMLElement;
        // }
        // const id = parent.id;
        // const extInfoDiv = document.getElementById(
        //     'hidden_' + id
        // ) as HTMLElement;
        // const baseInfoDiv = document.getElementById(id) as HTMLElement;
        // if (extInfoDiv.style.display !== 'table-row') {
        //     baseInfoDiv.style.borderBottom = 'none';
        //     extInfoDiv.style.display = 'table-row';
        // } else {
        //     extInfoDiv.style.display = 'none';
        // }
    };

    const deleteInvite = async () => {
        const res = await deleteInvitation(invite._id);

        if (res) {
            onChange(invite, true);
        }
    };

    return (
        <React.Fragment key={'frag' + user.id}>
            <tr
                onClick={!isInvite ? extendInfo : () => {}}
                id={user.id}
                className={
                    !isInvite
                        ? `${styles.clickable}  ${isActiveClass(user)}`
                        : ''
                }
            >
                {!isInvite && (
                    <td className={`${styles['user-status']}`}>
                        <span>
                            {userIsActive(user) ? 'Active' : 'Inactive'}
                        </span>
                    </td>
                )}
                {user.name && <td>{user.name}</td>}
                <td>{user.email}</td>
                {user.role && <td>{user.role}</td>}
                {isInvite && invite.isAdmin && <td>Admin</td>}
                {!isInvite && (
                    <>
                        <td>
                            <Select
                                defaultChecked={permissions}
                                type="checkbox"
                                name="permissions"
                                options={[
                                    'Approve',
                                    'Manage Protocols',
                                    'Manage Groups',
                                ]}
                                changeSelected={updatePermissions}
                            ></Select>
                        </td>
                    </>
                )}
                {!isInvite && (
                    <ExtendedInfoComponent
                        isActive={userIsActive}
                        onChange={handleChange}
                        user={user}
                        deptLicenses={deptLicenses}
                    />
                )}
                {isInvite && !invite.isAdmin && <td></td>}

                {isInvite && (
                    <td className={styles['invite-delete']}>
                        <SendReminders
                            infos={[{ email: user.email, id: user.id }]}
                        >
                            <FontAwesomeIcon icon={faBell} />
                        </SendReminders>
                        <DeleteInvite
                            onDelete={deleteInvite}
                            id={user.id}
                            email={user.email}
                        />
                    </td>
                )}
            </tr>
        </React.Fragment>
    );
};

export default UserRow;
